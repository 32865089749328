import get from 'lodash/get';
import isArray from 'lodash/isArray';

export default function arrToObj(arr, key) {
  if (!isArray(arr)) return {}
  return arr.reduce((obj, a, i) => {
    let thekey = i
    if (typeof key !== 'undefined') {
      if (typeof key === 'function') {
        thekey = key(a, i)
      } else {
        thekey = get(a, key)
      }
    }
    obj[thekey] = a
    return obj
  }, {})
}
