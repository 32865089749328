import { format } from 'd3-format'
import React from 'react'

import Box from './Box'
import Flex from './Flex'
import Text from './Text'
import { responsive } from './ThemeProvider/theme'

const f = format('.1f')

const Progress = ({
  value,
  total,
  label,
  color = 'pink.300',
  borderColor = 'black',
  ...props
}) => {
  return (
    <Flex alignItems="center" py="0.5em" {...props}>
      <Box flex="1">
        <Box
          borderColor={borderColor}
          p={responsive('0.25em', '0.375em')}
          bg="flower.lightGray"
          rounded="full"
        >
          <Box
            style={{ width: `${value / total * 100}%` }}
            bg={color}
            height={responsive('0.5em', '1em')}
            borderRadius="1.5em"
          />
        </Box>
      </Box>
      {/* <Flex
        position="relative"
        zIndex={1}
        rounded="full"
        px={responsive('1em', '2em')}
        py="0.5em"
        borderWidth={responsive('1px', '0.175em')}
        border="1px solid"
        borderColor={borderColor}
        bg="white"
        alignItems="flex-end"
      >
        <Text fontWeight="900" fontSize="2em" color={color}>
          {label ?? f(value / 1000)}
        </Text>
        <Text mb="0.5em" ml="0.5em" fontSize={responsive('2em', '1em')}>/{total}</Text>
      </Flex> */}
    </Flex>
  )
}

export default Progress
