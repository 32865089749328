import { graphql, useStaticQuery } from "gatsby"
import groupBy from "lodash/groupBy"
import { useMemo } from "react"
import arrToObj from "../utils/arrToObj"

const useImages = (quizName) => {
  const { allFile: { nodes: images } } = useStaticQuery(graphql`
    {
      allFile {
        nodes {
          relativeDirectory
          name
          ext
          publicURL
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              width: 480
              outputPixelDensities: [1, 2]
              quality: 95
            )
          }
        }
      }
    }
  `)
  const grouped = groupBy(images, 'relativeDirectory')
  const keyed = useMemo(() => arrToObj(grouped[quizName], d => `${d.name}${d.ext}`), [grouped, quizName])
  return keyed
}

export default useImages
