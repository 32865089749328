import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { timeout, interval } from 'd3-timer'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Center,
  AspectRatio,
} from '@chakra-ui/react';
import { navigate } from 'gatsby';

import Container from '../../components/Container';
import Box from '../../components/Box';
import Flex from '../../components/Flex';
import Text from '../../components/Text';
import Image from '../../components/Image';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Progress from '../../components/ProgressTest';
import { responsive } from '../../components/ThemeProvider/theme';

import Cards from './Cards';
import Controls from './Controls';
import hand from './hand.gif';

import useQuestions from './useQuestions';
import JustfontBg from './JustfontBg';
import useAnswerTools from '../useAnswerTools';
import Finished from '../Finished';

const dateLogic = [
  {
    max: 31,
    month: [1,3,5,7,8,10,12]
  },
  {
    max: 30,
    month: [4,6,9,11]
  },
  {
    max: 29,
    month: [2]
  }
]

let ticker
let ansTime
const secDuration = 3600
const sec = secDuration * 1000
const GamePage = ({ location, data }) => {
  const path = /\/q\/([^/]+)/g.exec(location.pathname)?.[1]
  const [month, setMonth] = useState()
  const [day, setDay] = useState()
  const { questions, results } = useQuestions({ ...data, path })
  const [tutorial, setTutorial] = useState(true)
  const [no, setNo] = useState(1)
  const [finish, setFinish] = useState()
  const [canAnswer, setCanAnswer] = useState(true)
  const [answers, setAnswers] = useState([])
  const [time, setTime] = useState(data.sec || sec)
  const answerTools = useAnswerTools(path)
  const isExist = dateLogic.filter(d => d.month.includes(month) && day >= 1 && day <= d.max)
  const isTouched = typeof month !== 'undefined' && typeof day !== 'undefined'
  const timeUp = () => {
    if (ticker) {
      ticker.stop()
    }
    setFinish(1)
  }
  const onAnswer = useCallback((i) => {
    setCanAnswer(false)
    setAnswers(ans => {
      const newAns = [...ans]
      newAns[no - 1] = {
        ans: i,
        duration: new Date() - ansTime,
        optionId: questions[no - 1]?.id,
      }
      return newAns
    })
    timeout(() => {
      if (time > 0 && no < questions.length) {
        ansTime = new Date()
        setNo(no + 1)
      } else {
      }
      setCanAnswer(true)
    }, 100)
  }, [no, questions])
  const tick = useCallback((elapsed) => {
    setTime(() => {
      if ((data.sec || sec) > elapsed) {
        return (data.sec || sec) - elapsed
      } else {
        return 0
      }
    })
  }, [])
  useEffect(() => {
    if (!tutorial && questions.length) {
      ansTime = new Date()
      ticker = interval(tick)
    }
  }, [tutorial, questions])
  return (
    <Flex flex={1} bg="white" overflow="hidden" mt="0">
      <Container width="100%" maxWidth={1200}>
        <Box.Relative height="100%">
          <Box.Relative px="1em" zIndex={5}>
            <Flex alignItems="flex-end">
              <Text fontFamily="Cormorant" lineHeight={1} color="flower.text" fontWeight={600} fontSize="3.75em">{no}</Text>
              <Text fontFamily="Cormorant" lineHeight={1} color="flower.number" fontWeight={600} fontSize="1.75em">/{questions.length}</Text>
            </Flex>
            <Progress color={`flower.buttonBg`} value={no} total={questions.length} position="relative" />
          </Box.Relative>
          {finish ? (
            <Center height="50vh">
              <Finished title="挑戰結束！" />
            </Center>
          ) : (
            <>
              <Box.Absolute top="0" left="-1em" right="-1em" bottom="0">
                <JustfontBg
                  path={path}
                  position="relative"
                  height="100%"
                  backgroundSize="cover"
                  as={Flex}
                  flexDirection={`column`}
                  justifyContent={`center`}
                >
                  {questions.length ? (
                    <Cards
                      no={no}
                      answers={answers}
                      onAnswer={onAnswer}
                      disabled={!canAnswer}
                      questions={questions}
                      borderColor={data.cardBorder}
                    />
                  ) : null}
                  {/* <Flex justifyContent={'space-between'} position={'absolute'} left="20%" right="20%" bottom={'1em'}>
                    <StaticImage width={90} height={90} placeholder="blurred" alt="圈" src="./flower/o.png" />
                    <StaticImage width={90} height={90} placeholder="blurred" alt="叉" src="./flower/x.png" />
                  </Flex> */}
                </JustfontBg>
              </Box.Absolute>
              <Controls path={path} onAnswer={onAnswer} disabled={!canAnswer} />
            </>
          )}
        </Box.Relative>
      </Container>
      {useMemo(() => (
        <Modal isOpen={tutorial || answers.length == questions.length} closeOnOverlayClick={false} isCentered>
          <ModalOverlay />
          <ModalContent
            maxWidth={responsive('90vw', '30em')}
            rounded="2em"
            textAlign="center"
            pb="3em"
          >
            {/* <ModalHeader
              textAlign="center"
              lineHeight="2"
              fontSize={responsive('1.75em', '1.75em')}
              fontWeight="500"
              letterSpacing="0.125em"
              color="flower.text"
            >測驗操作教學</ModalHeader> */}
            <ModalBody pt="3.75em" px={responsive('2em', '4em')}>
              {answers.length == questions.length ? (
                <Box textAlign="center">
                  <Text.NotoSerif letterSpacing="0.25rem" fontSize="1.75em" color="flower.text">
                    請輸入生日
                  </Text.NotoSerif>
                  <Text.NotoSerif lineHeight="1.8" color="flower.darkGray" mt="1.125em" fontSize="1.125em">
                    為了了解你的性格特質<br />
                    請輸入出生月份
                  </Text.NotoSerif>
                  <Flex alignItems={'center'} justifyContent={'center'} mt="1.125em">
                    <Input
                      color="black"
                      mx="0.625rem"
                      width="2em"
                      bg="#e2e2e2"
                      py="0.5rem"
                      px="0.5rem"
                      onChange={(e) => setMonth(+e.target.value)}
                      min={1}
                      max={12}
                      type="number"
                      textAlign="center"
                    />
                    <Text.NotoSerif fontSize="1.25em">月</Text.NotoSerif>
                    <Input
                      color="black"
                      mx="0.625rem"
                      width="2em"
                      bg="#e2e2e2"
                      py="0.5rem"
                      px="0.5rem"
                      onChange={(e) => setDay(+e.target.value)}
                      min={1}
                      max={31}
                      type="number"
                      textAlign="center"
                    />
                    <Text.NotoSerif fontSize="1.25em">日</Text.NotoSerif>
                  </Flex>
                  {!isExist.length && isTouched && <Text mt={'0.5rem'} color="red" fontSize="0.875em">請輸入正確的日期</Text> }
                  {/* {!isExist.length && <Text mt={'0.5rem'} color="red" fontSize="0.875em">日期請輸入1~31數字</Text> } */}
                </Box>
              ) : (
                <>
                  <Text.NotoSerif color="flower.darkGray" fontSize={'1.75em'} lineHeight="1.75">
                    {/* 請判斷畫面中關於感情的描述，<br />
                    像自己請往左滑，不像請往右滑<br />
                    若還沒有戀愛經驗，<br />
                    可以試著想想看，<br />
                    自己會不會有這些想法． */}
                    左右拖曳來回答
                  </Text.NotoSerif>
                  <AspectRatio mt="1em" ratio={698 / 500}>
                    <Image alt="手指示意Gif" src={hand} />
                  </AspectRatio>
                </>
              )}
            </ModalBody>
            {/* <ModalFooter display="block" p={responsive('2em', '1em')}>
              <Flex justifyContent="center" fontSize={responsive('1.5em', '1.5em')}>
                <HighlightNumber numberProps={{ fontSize: '2em', color: data.buttonColor ? `${data.buttonColor}.300` : 'yellow.500' }} number={data.sec / 1000 || secDuration}>秒快問快答</HighlightNumber>
              </Flex>
            </ModalFooter> */}
            <Box.Absolute width="75%" bottom="0" left="50%" transform="translate(-50%, 50%)">
              <Button
                display={!isExist.length && !tutorial && 'none'}
                isLoading={answerTools.loading}
                fontSize={responsive('1.25em', '2em')}
                letterSpacing={'0.25rem'}
                fontFamily="Noto Serif TC"
                justifyContent="center"
                bg="flower.buttonBg"
                _hover={{
                  bg: 'flower.buttonBg'
                }}
                color="white"
                width="full"
                borderRadius="full"
                py="0.875rem"
                height="auto"
                onClick={() => {
                  if (tutorial) {
                    answerTools.init().then(() => setTutorial(false))
                  } else {
                    const go = () => navigate(location.pathname.replace('play', 'result'), { state: { answers, questions, results, month, day } })
                    answerTools.updateAnswer([
                      ...answers,
                      { ans: month, optionId: 'month' },
                      { ans: day, optionId: 'day' },
                    ]).then(go)
                  }
                }}
              >{tutorial ? '我瞭解了' : '看測驗結果'}</Button>
            </Box.Absolute>
          </ModalContent>
        </Modal>
      ), [tutorial, answerTools])}
    </Flex>
  )
}

export default GamePage
