import React, { useEffect, useRef, useState } from 'react'
import Draggable from 'react-draggable';
import { interval } from 'd3-timer';

import Box from '../../../components/Box'
import CardQuestion from './CardQuestion'
import { responsive } from '../../../components/ThemeProvider/theme'

let animation
let answredAnimation
const thres = 0.10
const moveSpeed = 0.04

const Card = ({ disabled, onAnswer, answered, containerWidth, question, borderColor }) => {
  const [xOffset, setXOffset] = useState(0)
  const isAnswered = useRef()
  const restorePosition = () => {
    animation = interval(() => {
      setXOffset(x => {
        const to = Math.max(Math.abs(x) - containerWidth * moveSpeed, 0) * Math.sign(x)
        if (to === 0) animation.stop()
        return to
      })
    })
  }
  const answeredPosition = () => {
    isAnswered.current = true
    answredAnimation = interval(() => {
      setXOffset(x => {
        const to = Math.min(Math.abs(x) + containerWidth * moveSpeed, containerWidth)
        if (to === containerWidth) answredAnimation.stop()
        return to * Math.sign(answered)
      })
    })
  }
  useEffect(() => {
    if (answered && !isAnswered.current) {
      answeredPosition()
    }
  }, [answered])
  const s = Math.sign(xOffset)
  const abs = Math.abs(xOffset)
  const t = containerWidth * thres
  return (
    <Draggable
      axis="x"
      position={{ x: xOffset, y: 0 }}
      onStart={() => {
        if (animation && typeof animation.stop === 'function') {
          animation.stop()
        }
      }}
      onDrag={(e, { x }) => {
        setXOffset(x)
      }}
      onStop={() => {
        if (abs < t) {
          restorePosition()
        } else if (!disabled) {
          onAnswer(s)
        }
      }}
      disabled={disabled}
    >
      <Box height="100%" width="100%" userSelect={`none`}>
        <CardQuestion
          style={{ transform: `rotate(${xOffset / t * 15}deg)` }}
          position="relative"
          borderRadius={responsive('0.5em', '1em')}
          boxShadow="3px 3px 3px 3px rgba(0, 0, 0, 0.2)"
          bg="white"
          height="100%"
          transformOrigin="center bottom"
          disabled={disabled}
          question={question}
        />
      </Box>
    </Draggable>
  )
}

export default Card
