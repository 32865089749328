import sampleSize from 'lodash/sampleSize'
import chunk from 'lodash/chunk'
import { useMemo } from 'react'

// import data from '../../games/justfont.json'
import useImages from '../useImages'

const useQuestions = (data) => {
  const keyed = useImages(`${data.path}`)
  const ans = {
    [data.left]: -1,
    [data.right]: 1,
  }
  const questions = useMemo(() => {
    const qs = chunk(data.choices.map((q, i) => ({
      id: i + 1,
      image: keyed[q.image],
      answer: ans[q.answer],
      label: q.label,
      explain: q.explain,
    })), 18).reduce((res, d) => {
      const filterQ = sampleSize(d, 9)
      res = [...res, ...filterQ]
      return res
    }, [])
    return qs
  }, [])
  const results = useMemo(() => data.results.map(r => {
    const types = r.types.map(d => ({ ...d, image: keyed[d.image] }))
    return {
      ...r,
      types
    }
  }), [keyed])
  return {
    questions,
    results,
  }
}

export default useQuestions
