import { HStack, Spinner, Stack, Text } from '@chakra-ui/react'
import React from 'react'

const Finished = ({ title }) => {
  return (
    <Stack>
      <Text textAlign="center" fontSize="2em" fontWeight="900">{title}</Text>
      <HStack>
        <Text>結果計算中</Text>
        <Spinner />
      </HStack>
    </Stack>
  )
}

export default Finished
