import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import Flex from '../../../components/Flex'
import Box from '../../../components/Box'
import Button from '../../../components/Button'
import isIos from '../../../components/utils/isIos'

// import clock from './timer.svg'
import { responsive } from '../../../components/ThemeProvider/theme'

const CircleButton = props => (
  <Box
    // height={responsive('10em', '8em')}
    // width={responsive('10em', '8em')}
    transform={responsive('', 'translateY(-35vh)')}
  >
    <Button.Icon
      bg="transparent"
      rounded="full"
      width="100%"
      height="auto"
      _disabled={{
        opacity: 1,
      }}
      _hover={{
        bg: 'transparent'
      }}
      _active={{
        bg: 'transparent'
      }}
      {...props}
    />
  </Box>
)

const negative = {
  "xiahai-flower": <StaticImage placeholder="tracedSVG" src="../flower/o.png" />,
}
const positive = {
  "xiahai-flower": <StaticImage placeholder="tracedSVG" src="../flower/x.png" />,
}

const Controls = ({ onAnswer, disabled, path }) => {
  return (
    <Flex position="absolute" left={responsive("15%", 0)} right={responsive("15%", 0)} bottom={responsive(isIos ? '1.25em' : '2.25em', '1em')} justifyContent="space-between" alignItems="center">
      <CircleButton
        border="none"
        isDisabled={disabled}
        onClick={() => onAnswer(-1)}
        icon={negative[path]}
      />
      <CircleButton
        border="none"
        isDisabled={disabled}
        onClick={() => onAnswer(1)}
        icon={positive[path]}
      />
    </Flex>
  )
}

export default Controls
