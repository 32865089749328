import { Box, Center, Flex, Text } from '@chakra-ui/react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import isArray from 'lodash/isArray'
import React, { forwardRef } from 'react'
import { responsive } from '../../../components/ThemeProvider/theme'

const CardQuestion = forwardRef(({ question, disabled, hideLabel, ...props }, ref) => {
  const hasLabel = question.label && !hideLabel
  const noBorder = isArray(props.borderRadius) ? props.borderRadius.map(() => 0) : 0;
  return (
    <Flex flexDirection="column" ref={ref} {...props}>
      {question.image &&
        <Center
          overflow="hidden"
          transform="translateZ(0)"
          bg="white"
          borderRadius={props.borderRadius}
          position="relative"
          flex="1"
          opacity={disabled ? 0.5 : 1}
          pointerEvents="none"
          p={0}
          borderBottomLeftRadius={hasLabel && noBorder}
          borderBottomRightRadius={hasLabel && noBorder}
          mx="-1px"
          my="-1px"
        >
          <GatsbyImage image={getImage(question.image)} />
        </Center>
      }
      {hasLabel && (
        <Center px={responsive(2, 4)} py="4">
          <Text
            opacity={disabled ? 0.5 : 1}
            textAlign="center"
            fontSize={responsive('1.25em', '1.5em')}
          >{question.label}</Text>
        </Center>
      )}
      {/* <Box.Absolute
        top="-5em"
        pointerEvents="none"
        style={{
          [xOffset < 0 ? 'left' : 'right']: '33%',
          opacity: Math.min(Math.abs(xOffset) / (containerWidth * thres), 1),
        }}
      >
        <Box width={responsive('12em', '20em')}>
          {xOffset < 0 ? <Image src={recycleTag} /> : <Image src={trashTag} />}
        </Box>
      </Box.Absolute> */}
    </Flex>
  )
})

export default CardQuestion
