import { httpsCallable } from 'firebase/functions';
import { useState } from 'react'
import { useSessionStorage } from 'react-use';
import { useFunctions } from 'reactfire';

import { REF_KEY } from './UtmRecorder';

const promiseTimeout = () => new Promise((resolve) => {
  setTimeout(resolve, 5000);
});

const useAnswerTools = (quizId) => {
  const [loading, setLoading] = useState()
  const [answerId, setAnswerId] = useState()
  /* eslint-disable react-hooks/rules-of-hooks */
  const functions = typeof window !== 'undefined' && useFunctions()
  // const db = typeof window !== 'undefined' && useFirestore()
  /* eslint-enable react-hooks/rules-of-hooks */
  const [utm] = useSessionStorage(REF_KEY)
  const updateAnswer = async (answers, result) => {
    setLoading(true)
    try {
      if (process.env.NODE_ENV === 'production') {
        const updateData = httpsCallable(functions, 'updateFirestoreData')
        const toUpdate = { answers }
        if (result) {
          toUpdate.result = result
        }

        await Promise.race([
          updateData({
            collection: 'answers',
            docId: answerId,
            ...toUpdate,
          }),
          promiseTimeout(),
        ]);
      }

      setLoading(false)
    } catch (e) {
      console.error(e)
    }
  }

  const init = async () => {
    setLoading(true);
    if (quizId) {
      try {
        if (process.env.NODE_ENV === 'production') {
          const saveData = httpsCallable(functions, 'saveFirestoreData')
          const { data: id } = await saveData({
            collection: 'answers',
            id: quizId,
            utm,
          })
          setAnswerId(id)
        } else {
          setTimeout(() => setAnswerId('test'), 1000)
        }
      } catch (e) {
        console.error(e)
      }
    }
    setLoading(false);
  }
  return {
    inited: !!answerId,
    answerId,
    loading,
    updateAnswer,
    init,
  }
}

export default useAnswerTools
