import React from 'react'

import Seo from '../../../containers/Seo'
import Page from '../../../containers/DraggerGame/PlayGame'
import xiahai from '../../../quizes/xiahai-flower/data.json'

const Game = (props) => {
  return (
    <>
      <Seo.Flower />
      <Page data={xiahai} {...props} />
    </>
  )
}

export default Game
